import React from 'react'

function EstateAmenities() {
    const AminitiesData = [
        {
            image: "/park.svg",
            text: "All-Exclusive Verdant Trees"
        },
        {
            image: "/terrace.png",
            text: "Private Terrace Gardens"
        },
        {
            image: "/pond.png",
            text: "Serene Pond Ecosystem"
        },
        {
            image: "/balcony.svg",
            text: "Sylvan Forest Clubhouse"
        },
        {
            image: "/nature.png",
            text: "Adorning 250+ Trees"
        }, {
            image: "/pool.png",
            text: "Swimming Pool"
        },
        {
            image: "/rain-water.png",
            text: "Rainwater Recharge Basins"
        },
        {
            image: "/groundwater.png",
            text: "Groundwater Replenishment"
        },
        {
            image: "/linetrees.png",
            text: "Canopied Tree-Lined Entrance Stretch"
        },
        {
            image: "/driveways.png",
            text: "Designer-Modelled Driveways"
        },
    ]
    return (
        <>
            <div className='Amenities-main-parent' id='Amenities'>
                <div className='AssetsComponent-sub-parent'>
                    <h1>Amenities - </h1>
                </div>
                <div className='Amenities-sub-parent'>
                    <div className="row justify-content-center">
                        {
                            AminitiesData.length > 0 && AminitiesData.map((item, index) => (
                                <div className="col-md-2" key={index}>
                                    <div className='Aminities-content'>
                                        <div className='Amenities-image'>
                                            <img src={item.image} alt="" />
                                        </div>
                                        <p>{item.text} </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EstateAmenities;