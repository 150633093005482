import React, { useEffect, useState } from 'react'
import './Estateproperty.css'
import EstateNavbar from './EstateNavbar'
import EstatePropertyBanner from './EstatePropertyBanner'
import EstateMarqueeSlider from './EstateMarqueeSlider'
import EstateLayouts from './EstateLayouts'
import EstateAmenities from './EstateAmenities'
import EstateGalleyImages from './EstateGalleyImages'
import EstateFooter from './EstateFooter'
import EstateAssetsComponent from './EstateAssetsComponent'
import EstateTabsSection from './EstateTabsSection'
import EstateHighlights from './EstateHighlights'
import EstateLocation from './EstateLocation'
import EstateFaqSection from './EstateFaqSection'
import PropertyVideo from './PropertyVideo'
import EstateValidationModal from './EstateValidationModal'
const EstatePropertyDetails = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(true);
    const interval = setInterval(() => {
      setIsModalOpen(true);
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);
  
  return (
    <>
    <div className='PropertyDetails-main-parent'>
      <EstateNavbar/>
      <EstatePropertyBanner/>
      <EstateMarqueeSlider/>
      <EstateTabsSection/>
      <EstateHighlights/>
      <EstateAssetsComponent/>
      <EstateLayouts/>
      <EstateGalleyImages/>
      <PropertyVideo />
      <EstateAmenities/>
      <EstateLocation/>
      <EstateFaqSection/>
      <EstateFooter/>
      <EstateValidationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
    </>
  )
}

export default EstatePropertyDetails

