import React, { useState } from 'react'
import EstateValidationModal from './EstateValidationModal'

const EstateTabsSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='TabsSection-main-parent'>
                <div className='TabsSection-sub-parent'>
                    <div className="row align-items-end">
                        <div className="col-md-8">
                            <div className='TabsSection-top'>
                                <div className='linediv'>
                                </div>
                                <p>Overview</p>
                            </div>
                            <div className='TabsSection-left'>
                                <h1>Gravity Smera Gardens -</h1>
                                <p>An Ode To Your Personal Space, Gravity Smera Gardens presents you elegant luxurious biomes with all-exclusive 400 sq. ft. backyard gardens that are crafted for families with verdant green walkways and 500+ tree friends to embrace all the modern comforts tucked away from the noise. Move into a home that invites your peace.</p>
                                <div className="row">
                                    <div className="col-2 col-md-3">
                                        <div className='TabsSection-left-sub'>
                                            <h4>12</h4>
                                            <h5>ACRES</h5>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-3">
                                        <div className="TabsSection-left-sub">
                                            <h4>185</h4>
                                            <h5>VILLAS</h5>
                                        </div>

                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="TabsSection-left-sub">
                                            <h4>3 & 4 BHK</h4>
                                            <h5>DUPLEX VILLAS</h5>
                                        </div>

                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="TabsSection-left-sub">
                                            <h4>2100-3000</h4>
                                            <h5>SQ.FT</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='TabsSection-right'>
                                <p>If you're hurry, quick call for us</p>
                                <div className='TabsSection-right-sub1' role='button' onClick={showModal}>
                                    <p>Contact Us</p>
                                    <img src="/arrow-up-right.svg" alt="" />
                                </div>
                                <div className='TabsSection-right-sub2'>
                                    <h3>Location Details:</h3>
                                    <p>Gravity Smera Gardens, Off Hosur Road, Manchanahalli Village, Attibele Hobli, Anekal Taluk, Bangalore Urban District</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EstateValidationModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    )
}

export default EstateTabsSection

