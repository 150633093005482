import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/Components/styles.css";
import './App.css'
import EstatePropertyDetails from "./Components/EstatePropertyDetails";
import Thankyou from "./Components/Thankyou";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<EstatePropertyDetails />} />
          <Route path="thankyou" element={<Thankyou />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
