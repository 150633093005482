import React from 'react'
import './Estatehighlights.css'
const EstateHighlights = () => {
  return (
    <>
      <div className='Highlights-main-parent' id='Highlights'>
        <div className='Highlights-sub-parent'>
          <h1>Signature <span className='span-subtext'>Highlights -</span></h1>
        </div>
        <div className='PropertyDetails-Highlights'>
          <div className='d-flex Highlights-borderdcfgbhnj firstrow-parent'>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/linetrees.png" alt="" />
              </div>
              <h6>CANOPIED</h6>
              <p>Entrance Stretch</p>
            </div>
            <div className='Highlights-bordercircle2'>
              <div className='Highlights-image'>
                <img src="/stoneroads.png" alt="" />
              </div>
              <h6>ELECTRONIC CITY</h6>
              <p>10 Mins Drive</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/front.png" alt="" />
              </div>
              <h6>Naturing 400 SQ.FT</h6>
              <p>Backyard Trees</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/front.png" alt="" />
              </div>
              <h6>POND ECOSYSTEM</h6>
              <p>Serene</p>
            </div>
          </div>
          <div className='d-flex Highlights-borderdcfgbhnj'>
            <div className='Highlights-bordercircle2'>
              <div className='Highlights-image'>
                <img src="/front.png" alt="" />
              </div>
              <h6>BACKYARD GARDENS</h6>
              <p>Nurturing 4000 SQ.FT</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/park.svg" alt="" />
              </div>
              <h6>LUSH</h6>
              <p>1500+ Trees</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/park.svg" alt="" />
              </div>
              <h6>Spanning</h6>
              <p>12+ Acres</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/park.svg" alt="" />
              </div>
              <h6>Secure</h6>
              <p>Gated Community</p>
            </div>
          </div>

          {/* ---------------------------------------------------------------------- */}
          <div className='d-flex Highlights-borderdcfgbhnj'>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>Ground Water</h6>
              <p>Replenishment</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>Rain Water</h6>
              <p>Recharge Basins</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>WALKWAYS</h6>
              <p>Verdant Green</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/groundwater.png" alt="" />
              </div>
              <h6>DRIVEWAYS</h6>
              <p>Designer-Modelled</p>
            </div>
          </div>

          <div className='d-flex Highlights-borderdcfgbhnj'>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/rain-water.png" alt="" />
              </div>
              <h6>CLUBHOUSE</h6>
              <p>Sylvan Forest</p>
            </div>
            <div className='Highlights-bordercircle2'>
              <div className='Highlights-image'>
                <img src="/walking.png" alt="" />
              </div>
              <h6>PAVED ROADS</h6>
              <p>Cobble Stone</p>
            </div>
            <div className='Highlights-bordercircle'>
              <div className='Highlights-image'>
                <img src="/driveways.png" alt="" />
              </div>
              <h6>500 sq.ft</h6>
              <p>Terrace Garden</p>
            </div>
          </div>
        </div>
        <div className='scroll-more-parent'>
          <div className='scroll-more'>
            <img src="/left-arrow.png" alt="" />
          </div>
          <span>Scroll For More</span>
        </div>


      </div>
    </>

  )
}

export default EstateHighlights
