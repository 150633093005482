import React, { useState } from 'react'
import EstateValidationModal from './EstateValidationModal';

const EstateLocation = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
        <>
            <div className='Location-main-parent' id='Location'>
                <div className='AssetsComponent-sub-parent'>
                    <h1>Location -  </h1>
                    <div className='Assetstab1-parent' role='button' onClick={showModal}>
                        <div class="TabsSection-right-sub1"><p>Need a Location Tour ?</p><img src="/arrow-up-right.svg" alt="" /></div>
                    </div>
                </div>
                <div className='Location-sub-parent'>
                    <div className='Location-map-parent'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248974.44048530687!2d77.40759417745437!3d12.82869127810683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae713dc6ed4daf%3A0x94c4ad11e436f1b5!2sGravity%20Smera%20Gardens!5e0!3m2!1sen!2sin!4v1721733164049!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                    <h2>Ease of Access & Prime Landmarks</h2>
                    <div className='Location-below-section'>
                        <div className="row">
                            <div className="col-md-4">
                                <div className='Location-below-section-sub'>
                                    <ul>
                                        <li><strong>6 mins away from CHANDAPURA MARKET</strong></li>
                                        <li><strong>8 mins away from ATTIBELE TOLL GATE</strong></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='Location-below-section-sub'>
                                    <ul>
                                        <li><strong>500 meters off HOSUR ROAD</strong></li>
                                        <li><strong>Nearby upcoming LULU MALL</strong></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='Location-below-section-sub'>
                                    <ul>
                                        <li><strong>10 mins drive ELECTRONIC CITY</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EstateValidationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
        </>
    )
}

export default EstateLocation;

