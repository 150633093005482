import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const PropertyVideo = () => {

    return (
        <>
            <div className='Location-main-parent' id='Location'>
                <div className='AssetsComponent-sub-parent'>
                    <h1>Video -  </h1>
                </div>
                <div className='Location-sub-parent'>
                    <div className='Location-map-parent'>
                        <iframe src="https://www.youtube.com/embed/K9J6VBefSVM" width="500" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyVideo;