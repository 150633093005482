import { Autoplay, Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const EstateGalleyImages = () => {
    return (
        <div className='' style={{borderTop:"3px solid #1D4734",padding:'25px 0'}}>
            <div className='AssetsComponent-sub-parent' id='Gallery'>
                <h1 style={{padding:'20px 0'}}>Gallery - </h1>
            </div>

            <Swiper
                // install Swiper modules
                modules={[Pagination,Autoplay]}
                spaceBetween={30}
                slidesPerView={3}
                autoplay={true}
                loop={true}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 2,
                    },
                    1080: {
                        slidesPerView: 3,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                }}
            >
                <br /><br />  <br /><br /><br />
                {[
                    '/smera-garden-banner.jpg',
                    '/smeraimg1.webp',
                    '/smeraimg2.webp',
                    '/smeraimg3.webp',
                    '/smeraimg4.webp',
                    '/smeraimg5.webp',
                    '/smeraimg7.webp',
                ].map((src, index) => (
                    <SwiperSlide key={index}>
                        <div className='galleryimages-swiper-image'>
                            <img src={src} alt="swiper" />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default EstateGalleyImages
