import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

const EstateMarqueeSlider = () => {
  
    return (
        <>
            <div className='MarqueeSlider-main-parent'>
                <div className='MarqueeSlider-parent MarqueeSlider-below-parent'>
                    <Marquee gradient={false} speed={50}>
                        <div className='d-flex gap-5 align-items-center'>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                            <p>Gravity Smera Gardens</p>
                        </div>
                    </Marquee>
                </div>
           
            </div>
        </>
    );
};

export default EstateMarqueeSlider;

