import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EstateNavbar = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(0);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const dropdownRefPC = useRef(null);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const handleLinkClick = () => {
        setOpen(false);
    };

    return (
        <>
            <style>
                {`
                .HomeNavbar-mainparent li.nav-item.active {
                    border: 1px solid #000;
                    border-radius: 20px;
                    padding: 0px 15px;
                }
                @media (max-width: 991px) {
                    .HomeNavbar-mainparent .navbar-collapse {
                        display: none !important;
                    }
                    .offcanvas {
                        display: block !important;
                    }
                }
                @media (min-width: 992px) {
                    .offcanvas {
                        display: none !important;
                    }
                }
                .offcanvas {
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 300px;
                    height: 100%;
                    background-color: white;
                    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
                    transform: translateX(100%);
                    transition: transform 0.3s ease;
                    z-index: 1050;
                }
                .offcanvas.show {
                    transform: translateX(0);
                }
                .offcanvas-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    border-bottom: 1px solid #ddd;
                }
                .offcanvas-body {
                    padding: 15px;
                }
                .offcanvas-title {
                    margin: 0;
                }
                `}
            </style>
            <div className="HomeNavbar-mainparent">
                <div className="HomeNavbar-sub-parent">
                    <div className="container-xxl">
                        <nav className="navbar navbar-expand-lg">
                            <div className='Gravity-logo'>
                                <Link to="/">
                                    <img src="/smera-garden-logo-custome1.png" alt="" />
                                </Link>
                            </div>
                            <button className="navbar-toggler" type="button" onClick={handleOpen}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <div className="row p-0 m-0 w-100 justify-content-end align-items-center">
                                    <div className="col-md-9">
                                        <ul className="navbar-nav mr-auto">
                                            <li className={`nav-item `}>
                                                <a className="nav-link" href="/">Home</a>
                                            </li>
                                            <li className={`nav-item`}>
                                                <a className="nav-link" href="#Highlights">Highlights</a>
                                            </li>
                                            <li className={`nav-item `}>
                                                <a className="nav-link" href="#Assets">Assets</a>
                                            </li>
                                            <li className={`nav-item `}>
                                                <a className="nav-link" href="#Layouts">Layouts</a>
                                            </li>
                                            <li className={`nav-item `}>
                                                <a className="nav-link" href="#Gallery">Gallery</a>
                                            </li>
                                            <li className={`nav-item `}>
                                                <a className="nav-link" href="#Amenities">Amenities</a>
                                            </li>
                                            <li className={`nav-item `}>
                                                <a className="nav-link" href="#Location">Location</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-inline my-2 my-lg-0">
                                            <a href="tel:(+91) 95389 44994">
                                                <div className='contact-number-button'>
                                                    <button className="btn my-2 my-sm-0 d-flex gap-2 align-items-center" type="submit"><img src="/phoneicon-navbar.svg" alt="" /><span>+91 95389 44994</span></button>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`offcanvas ${open ? 'show' : ''}`} tabIndex="-1">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title">
                                        <div className='Gravity-logo'>
                                            <Link to="/">
                                                <img src="/smera-garden-logo-custome1.png" alt="" />
                                            </Link>
                                        </div>
                                    </h5>
                                    <button type="button" className="btn-close" onClick={handleOpen}></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav mr-auto">
                                        <li className={`nav-item `}>
                                            <a className="nav-link" href="#banner" onClick={handleLinkClick}>Home</a>
                                        </li>
                                        <li className={`nav-item`}>
                                            <a className="nav-link" href="#Highlights" onClick={handleLinkClick}>Highlights</a>
                                        </li>
                                        <li className={`nav-item `}>
                                            <a className="nav-link" href="#Assets" onClick={handleLinkClick}>Assets</a>
                                        </li>
                                        <li className={`nav-item `}>
                                            <a className="nav-link" href="#Layouts" onClick={handleLinkClick}>Layouts</a>
                                        </li>
                                        <li className={`nav-item `}>
                                            <a className="nav-link" href="#Gallery" onClick={handleLinkClick}>Gallery</a>
                                        </li>
                                        <li className={`nav-item `}>
                                            <a className="nav-link" href="#Amenities" onClick={handleLinkClick}>Amenities</a>
                                        </li>
                                        <li className={`nav-item `}>
                                            <a className="nav-link" href="#Location" onClick={handleLinkClick}>Location</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 navbar-header-button">
                                    <div className="form-inline my-2 my-lg-0">
                                        <a href="tel:(+91) 95389 44994">
                                            <div className='contact-number-button'>
                                                <button className="btn my-2 my-sm-0 d-flex gap-2 align-items-center" type="submit"><img src="/phoneicon-navbar.svg" alt="" /><span>+91 95389 44994</span></button>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EstateNavbar;
