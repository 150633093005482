import { message, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const EstateValidationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phonenumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.email.trim()) errors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
    if (!formData.subject.trim()) errors.subject = "Subject is required";
    if (!formData.phonenumber.trim())
      errors.phonenumber = "Phone number is required";
    if (!/^\d{10}$/.test(formData.phonenumber))
      errors.phonenumber = "Phone number is invalid";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
        message.loading("Please wait! Your response is sending.")
      try {
        const response = await fetch("https://smeragardens.gravityhomes.in/forms/contact.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json(); // Parse JSON response here

        if (response.ok) {
          // Check if the response status is OK (200-299)
          if (data.status === "success") {
            // Redirect to another page after successful form submission
            window.location.href = "https://smeragardens.gravityhomes.in/forms/thankyou.html"; // Replace with your desired redirect URL
          } else {
            // Handle the errors or display the error message from the backend
            console.error("Error:", data.message);
            message.error("An error occurred. Please try again.")
            setModalMessage(
              data.message || "An error occurred. Please try again."
            );
          }
        } else {
          // Handle non-2xx HTTP responses
          console.error("Error:", data.message);
          message.error("An error occurred. Please try again.")
          setModalMessage(
            data.message || "An error occurred. Please try again."
          );
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        message.error("Error submitting form.")
        setModalMessage("An error occurred. Please try again later.");
      }
    } else {
      setErrors(formErrors);
      message.error("Please correct the errors in the form.")
      setModalMessage("Please correct the errors in the form.");
    }

    setIsLoading(false);
    // setShowModal(true);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log("currentUrl", currentUrl);
    const urlInput = document.getElementById("currentUrlInputInModal");
    if (urlInput) {
      urlInput.value = currentUrl;
    }
  }, [formData]);

  return (
    <Modal
      footer={null}
      title="Let's Talk to Our Experts"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <form onSubmit={handleSubmit}>
        <div className="ContactForm-sub-right">
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="name"
                placeholder="Name *"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {errors.name && (
                <p className="error text-danger">{errors.name}</p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="email"
                placeholder="Email *"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && (
                <p className="error text-danger">{errors.email}</p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="subject"
                placeholder="City"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
              {errors.subject && (
                <p className="error text-danger">{errors.subject}</p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="phonenumber"
                placeholder="Phone *"
                value={formData.phonenumber}
                onChange={handleInputChange}
                required
              />
              {errors.phonenumber && (
                <p className="error text-danger">{errors.phonenumber}</p>
              )}
            </div>
            <div className="col-md-12">
              <textarea
                name="message"
                placeholder="Hello I am interested in..."
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </div>
            <input
              type="hidden"
              id="currentUrlInputInModal"
              name="currentUrl"
            />
            <div className="Sendnowbutton">
              <button
                type="submit"
                disabled={isLoading}
                className={isLoading ? "loading" : ""}
              >
                {isLoading ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Send Now ➜"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EstateValidationModal;
