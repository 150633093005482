import React from 'react';
import './thankyou.css'

const Thankyou = () => {
  return (
    <div className="card-parent">
        <div className="card">
        <div className='card-in'>
          <i className="checkmark">✓</i>
        </div>
          <h1 className='success'>Success</h1> 
          <p className='success-message'>We received your valuable request.<br/> we'll be in touch shortly!</p>
        </div>
    </div>
  )
}

export default Thankyou